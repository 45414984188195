import React, { useEffect, useState } from 'react';
import Layout from './Layout';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../client/client';
import { useLogin } from '../context/LoginContext';

const PricesForm = () => {
    const [loading, setLoading] = useState(true);
    const [showSuccessDialog, setShowSuccessDialog] = useState(false);
    const { getSessionAuth } = useLogin();
    const navigate = useNavigate();
    const methodOptions = ['Lineal', 'Acelerada', 'Saldo decreciente'];

    useEffect(() => {
        const sessionAuth = async () => {
            const session = await getSessionAuth();
            if (session) {
                setLoading(false);
            } else {
                navigate("/", { replace: true });
            }
        };
        sessionAuth();
    }, [getSessionAuth, navigate]);

    const [formData, setFormData] = useState({
        metodo: 'Lineal',
        razon_social: '',
        porcentaje: 0,
        year: 0,
        dep_mensual: 0,
    });

    const [equiposList, setEquiposList] = useState([]);
    const [selectedEquipo, setSelectedEquipo] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data: equiposData, error: equiposError } = await supabase
                    .from('equipos')
                    .select('id, title, attendant');

                if (equiposError) {
                    console.error('Error fetching equipos data:', equiposError);
                } else {
                    const sortedEquipos = equiposData.sort((a, b) => {
                        const aTitle = a.title.match(/^(\D+)(\d*)$/);
                        const bTitle = b.title.match(/^(\D+)(\d*)$/);

                        if (aTitle && bTitle) {
                            const aText = aTitle[1].trim();
                            const bText = bTitle[1].trim();
                            const aNumber = aTitle[2] ? parseInt(aTitle[2], 10) : 0;
                            const bNumber = bTitle[2] ? parseInt(bTitle[2], 10) : 0;

                            if (aText === bText) {
                                return aNumber - bNumber;
                            } else {
                                return aText.localeCompare(bText);
                            }
                        } else {
                            return a.title.localeCompare(b.title);
                        }
                    });
                    setEquiposList(sortedEquipos);
                }
            } catch (error) {
                console.error('Error fetching equipos data:', error.message);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (formData.active_date) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                shipping_date: prevFormData.active_date,
            }));
        }
    }, [formData.active_date]);

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'shipping_date') {
            setFormData((prevFormData) => ({
                ...prevFormData,
                shipping_date: value,
                active_date: prevFormData.active_date || value,
            }));
        } else if (name === 'active_date') {
            setFormData((prevFormData) => ({
                ...prevFormData,
                active_date: value,
            }));
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value,
            }));
        }
    };


    const handleEquipoChange = (e) => {
        const selectedId = e.target.value;
        setSelectedEquipo(selectedId);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const { error } = await supabase.from('costos').upsert([
                {
                    id_equipo: selectedEquipo,
                    costos: formData.costos,
                    razon_social: formData.razon_social,
                    porcentaje: formData.porcentaje,
                    year: formData.year,
                    dep_mensual: formData.dep_mensual,
                    metodo: formData.metodo,
                },
            ]);

            if (error) {
                console.error('Error updating data:', error);
            } else {
                setShowSuccessDialog(true);
            }
        } catch (error) {
            console.error('Error updating data:', error.message);
        }
    };

    const handleAccept = () => {
        setShowSuccessDialog(false);
        navigate("/equipos");
    };

    return (
        <Layout>
            <form onSubmit={handleSubmit}>
                <h1 className='text-3xl font-bold text-[#058237] flex justify-center pb-10'>COSTOS</h1>
                <div className="form-row" style={{ display: 'flex', width: '90%' }}>
                    <div className="field">
                        <label className='text-xs font-semibold'>
                            Equipo:
                            <select
                                name="equipo"
                                value={selectedEquipo || ''}
                                onChange={handleEquipoChange}
                            >
                                <option value="" disabled>Selecciona un equipo</option>
                                {equiposList.map((equipo) => (
                                    <option key={equipo.id} value={equipo.id}>
                                        {`${equipo.title} - ${equipo.attendant}`}
                                    </option>
                                ))}
                            </select>
                        </label>
                    </div>
                    <div className="field" style={{ display: 'flex', width: '40%' }}>
                        <label className='text-xs font-semibold'>
                            Fecha de compra:
                            <div style={{ display: 'flex' }}>
                                <input
                                    type="date"
                                    name="shipping_date"
                                    value={formData.shipping_date}
                                    onChange={handleChange}
                                />
                            </div>
                        </label>
                    </div>

                    <div className="field" style={{ display: 'flex', width: '40%' }}>
                        <label className='text-xs font-semibold'>
                            Fecha de uso de activo:
                            <div style={{ display: 'flex' }}>
                                <input
                                    type="date"
                                    name="active_date"
                                    value={formData.active_date}
                                    onChange={handleChange}
                                />
                            </div>
                        </label>
                    </div>
                </div>
                <div className="form-row" style={{ display: 'flex', width: '90%' }}>
                    <div className="field">
                        <label className='text-xs font-semibold'>
                            Razón social:
                            <div style={{ display: 'flex', width: '100%' }}>
                                <input
                                    type="text"
                                    name="razon_social"
                                    value={formData.razon_social}
                                    onChange={handleChange}
                                />
                            </div>
                        </label>
                    </div>
                    <div className="field" style={{ display: 'flex', width: '40%' }}>
                        <label className='text-xs font-semibold'>
                            Monto del producto:
                            <div style={{ display: 'flex' }}>
                                <input
                                    type="number"
                                    name="costos"
                                    value={formData.costos}
                                    onChange={handleChange}
                                />
                            </div>
                        </label>
                    </div>

                    <div className="field" style={{ display: 'flex', width: '40%' }}>
                        <label className='text-xs font-semibold'>
                            Año:
                            <div style={{ display: 'flex' }}>
                                <input
                                    type="number"
                                    name="year"
                                    value={formData.year}
                                    onChange={handleChange}
                                />
                            </div>
                        </label>
                    </div>
                </div>
                <div className="form-row" style={{ display: 'flex', width: '60%' }}>
                    <div className="field">
                        <label className='text-xs font-semibold'>
                            Porcentaje:
                            <div style={{ display: 'flex' }}>
                                <input
                                    type="number"
                                    name="porcentaje"
                                    value={formData.porcentaje}
                                    onChange={handleChange}
                                />
                            </div>
                        </label>
                    </div>
                    <div className="field">
                        <label className='text-xs font-semibold'>
                            Depreciación mensual:
                            <div style={{ display: 'flex' }}>
                                <input
                                    type="number"
                                    name="dep_mensual"
                                    value={formData.dep_mensual}
                                    onChange={handleChange}
                                />
                            </div>
                        </label>
                    </div>
                    <div className="field">
                        <label className='text-xs font-semibold'>
                            Método:
                            <div className="styled-select">
                                <select
                                    name="metodo"
                                    value={formData.metodo}
                                    onChange={handleChange}
                                    className="styled-select"
                                >
                                    <option value="">Selecciona</option>
                                    {methodOptions.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </label>
                    </div>
                </div>
                <div className="form-row">
                    <button className="bg-[#058237] hover:bg-green transition-colors duration-300 text-base cursor-pointer mt-7 text-white font-semibold py-2 px-4 rounded-md w-[30%]" type="submit">Guardar</button>
                </div>

                {showSuccessDialog && (
                    <div className="success-dialog">
                        <p>Se ha guardado exitosamente</p>
                        <button onClick={handleAccept} className="bg-[#058237] hover:bg-green transition-colors duration-300 text-base cursor-pointer mt-7 text-white font-semibold py-2 px-4 rounded-md w-[30%]">Aceptar</button>
                    </div>
                )}
            </form>
        </Layout>
    );
};

export default PricesForm;
