import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as XLSX from "xlsx";
import DataGrid from "react-data-grid";
import JsBarcode from "jsbarcode";
import "jspdf-autotable";
import jsPDF from "jspdf";
import { supabase } from "../client/client";
import { useLogin } from "../context/LoginContext";
import Layout from "./Layout";
import Loading from "./Loading";
import DownloadIcon from "./DownloadIcon";
import CreateIcon from "./CreateIcon";
import "react-data-grid/lib/styles.css";
import FetchSoftware from "./FetchSoftware";
import FetchBackups from "./FetchBackups";
import FetchMaintenance from "./FetchMaintenance";
import FetchBackupsServer from "./FetchBackupsServer";
import FetchPrices from "./FetchPrices";
import FetchProvider from "./FetchProvider";

const ProductsPage = () => {
  const { id } = useParams();
  const [first, setfirst] = useState(false)
  const [second, setsecond] = useState(false)
  const [third, setthird] = useState(false)
  const [fourth, setfourth] = useState(false)
  const [fifth, setfifth] = useState(false)
  const [sixth, setsixth] = useState(false)
  const [seventh, setseventh] = useState(false)
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filters, setFilters] = useState({
    title: "",
    brand: "",
    state: "",
    attendant: "",
    area: "",
  });

  const { getSessionAuth } = useLogin();
  const navigate = useNavigate();

  useEffect(() => {
    const sessionAuth = async () => {
      const session = await getSessionAuth();
      if (session) {
        await fetchData();
        setLoading(false);
      } else {
        navigate("/", { replace: true });
      }
    };
    sessionAuth();
  }, [getSessionAuth, navigate]);

  const handleImageClick = (row) => {
    navigate(`/informacion/${row.id}`);
  };

  useEffect(() => {
    const filteredData = data.filter((row) => {
      return (
        row.title.includes(filters.title) &&
        row.brand.includes(filters.brand) &&
        row.state.includes(filters.state) &&
        row.area.includes(filters.area) &&
        row.attendant.includes(filters.attendant)
      );
    });
    setFilteredData(filteredData);
  }, [data, filters]);

  const handleFilterChange = (filterName, value) => {
    setFilters((prevFilters) => ({ ...prevFilters, [filterName]: value }));
  };

  const columnsHardware = [
    {
      field: "image1",
      name: "Foto",
      key: "image1",
      width: 30,
      renderCell: ({ row }) => (
        <Link to={`/informacion/${row.id}`} onClick={() => handleImageClick(row)}>
          <img className="" src={row.image1} alt={row.title} />
        </Link>
      ),
    },
    { field: "attendant", name: "Encargado", width: 220, key: "attendant" },
    {
      field: "title",
      name: "Nombre",
      width: 300,
      key: "title",
      renderCell: ({ row }) => {
        return (
          <Link to={`/equipos/${row.id}/editar`} underline="always">
            {row.title}
          </Link>
        );
      },
    },
    { field: "area", name: "Área", width: 300, key: "area" },
    { field: "brand", name: "Marca", width: 180, key: "brand" },
    { field: "state", name: "Estado", width: 250, key: "state" },
  ];

  const gridStyle = {
    minHeight: '500px',
  };

  const handleDownloadPDF = () => {
    const pdf = new jsPDF({
      unit: 'cm',
      format: [21, 29.7],
    });

    const etiquetaWidth = 6.3;
    const etiquetaHeight = 2.2;

    const rowsPerPage = 11;
    const columnsPerPage = 3;
    const margenIzquierdo = 0.6;
    const separacionHorizontal = 0.5;

    data.forEach((product, index) => {
      if (index > 0 && index % (rowsPerPage * columnsPerPage) === 0) {
        pdf.addPage();
      }

      const colIndex = index % columnsPerPage;
      const rowIndex = Math.floor(index / columnsPerPage) % rowsPerPage;

      const startX = colIndex * (etiquetaWidth + separacionHorizontal) + margenIzquierdo;
      const startY = rowIndex * (etiquetaHeight + 0.4);

      const canvas = document.createElement("canvas");

      JsBarcode(canvas, product.id, { format: "CODE128", fontSize: 30, width: 1.4 });

      const barcodeDataURL = canvas.toDataURL("image/png");
      pdf.addImage(barcodeDataURL, "PNG", startX, startY, etiquetaWidth, etiquetaHeight);

      pdf.setFontSize(8);
      const text = `${product.title} - ${product.area}`;
      pdf.text(text, startX, startY + etiquetaHeight + 0.3);
    });

    pdf.save("etiquetas.pdf");
  };

  const handleDownloadExcel = () => {
    const originalSheet = XLSX.utils.json_to_sheet(data);

    const additionalSheet = XLSX.utils.json_to_sheet([
      { "Column1": "CÁMARA DE COMERCIO E INDUSTRIA DE AREQUIPA" },
      { "Column1": "FICHA DE EQUIPOS" }
    ]);

    const combinedSheet = XLSX.utils.aoa_to_sheet([
      ["CÁMARA DE COMERCIO E INDUSTRIA DE AREQUIPA"],
      ["FICHA DE EQUIPOS"],
      [],
      ...XLSX.utils.sheet_to_json(originalSheet, { header: 1 })
    ]);

    XLSX.writeFile({ Sheets: { Combined: combinedSheet }, SheetNames: ['Combined'] }, 'Hardware.xlsx');
  };

  const handleDownloadExcelBackups = async () => {
    try {
      const { data: backupsData, error: backupsError } = await supabase.from("backups").select("*");
      if (backupsError) {
        console.error("Error fetching backup data:", backupsError);
        return;
      }
      const { data: equiposData, error: equiposError } = await supabase.from("equipos").select("id, title, attendant");
      if (equiposError) {
        console.error("Error fetching equipos data:", equiposError);
        return;
      }
      const pricesWithEquiposData = backupsData.map((backup) => {
        const equipo = equiposData.find((equipo) => equipo.id === backup.id_equipo);
        return {
          ...backup,
          title_equipo: equipo.title,
          area_equipo: equipo.area,
        };
      });

      const originalSheet = XLSX.utils.json_to_sheet(pricesWithEquiposData);

      const additionalSheet = XLSX.utils.json_to_sheet([
        { "Column1": "CÁMARA DE COMERCIO E INDUSTRIA DE AREQUIPA" },
        { "Column1": "FICHA DE BACKUPS" },
      ]);

      const combinedSheet = XLSX.utils.aoa_to_sheet([
        ["CÁMARA DE COMERCIO E INDUSTRIA DE AREQUIPA"],
        ["FICHA DE BACKUPS"],
        [],
        ...XLSX.utils.sheet_to_json(originalSheet, { header: 1 })
      ]);

      XLSX.writeFile({ Sheets: { Combined: combinedSheet }, SheetNames: ['Combined'] }, 'Costos.xlsx');
    } catch (error) {
      console.error("Error fetching and processing data:", error.message);
    }
  };

  const handleDownloadExcelBackupsServer = async () => {
    try {
      const { data: backupsData, error: backupsError } = await supabase.from("backups_server").select("*");
      if (backupsError) {
        console.error("Error fetching backup data:", backupsError);
        return;
      }
      const { data: equiposData, error: equiposError } = await supabase.from("equipos").select("id, title, attendant");
      if (equiposError) {
        console.error("Error fetching equipos data:", equiposError);
        return;
      }
      const backupsWithEquiposData = backupsData.map((backup) => {
        const equipo = equiposData.find((equipo) => equipo.id === backup.id_equipo);
        return {
          ...backup,
          title_equipo: equipo.title,
          attendant_equipo: equipo.attendant,
        };
      });

      const originalSheet = XLSX.utils.json_to_sheet(backupsWithEquiposData);

      const additionalSheet = XLSX.utils.json_to_sheet([
        { "Column1": "CÁMARA DE COMERCIO E INDUSTRIA DE AREQUIPA" },
        { "Column1": "FICHA DE BACKUPS DE SERVIDORES" },
      ]);

      const combinedSheet = XLSX.utils.aoa_to_sheet([
        ["CÁMARA DE COMERCIO E INDUSTRIA DE AREQUIPA"],
        ["FICHA DE BACKUPS DE SERVIDORES"],
        [],
        ...XLSX.utils.sheet_to_json(originalSheet, { header: 1 })
      ]);

      XLSX.writeFile({ Sheets: { Combined: combinedSheet }, SheetNames: ['Combined'] }, 'backupsServidor.xlsx');
    } catch (error) {
      console.error("Error fetching and processing data:", error.message);
    }
  };

  const handleDownloadExcelMaintenance = async () => {
    try {
      const { data: maintenanceData, error: maintenanceError } = await supabase.from("maintenance").select("*");
      if (maintenanceError) {
        console.error("Error fetching maintenance data:", maintenanceError);
        return;
      }
      const { data: equiposData, error: equiposError } = await supabase.from("equipos").select("id, title, attendant");
      if (equiposError) {
        console.error("Error fetching equipos data:", equiposError);
        return;
      }
      const maintenanceWithEquiposData = maintenanceData.map((maintenance) => {
        const equipo = equiposData.find((equipo) => equipo.id === maintenance.id_equipo);
        return {
          ...maintenance,
          title_equipo: equipo.title,
          attendant_equipo: equipo.attendant,
        };
      });

      const originalSheet = XLSX.utils.json_to_sheet(maintenanceWithEquiposData);

      const additionalSheet = XLSX.utils.json_to_sheet([
        { "Column1": "CÁMARA DE COMERCIO E INDUSTRIA DE AREQUIPA" },
        { "Column1": "DETALLE DE MANTENIMIENTO" }
      ]);

      const combinedSheet = XLSX.utils.aoa_to_sheet([
        ["CÁMARA DE COMERCIO E INDUSTRIA DE AREQUIPA"],
        ["DETALLE DE MANTENIMIENTO"],
        [],
        ...XLSX.utils.sheet_to_json(originalSheet, { header: 1 })
      ]);

      XLSX.writeFile({ Sheets: { Combined: combinedSheet }, SheetNames: ['Combined'] }, 'Mantenimiento.xlsx');
    } catch (error) {
      console.error("Error fetching and processing data:", error.message);
    }
  };

  const handleDownloadExcelSoftware = async () => {
    try {
      const { data: softwareData, error: softwareError } = await supabase.from("licencias").select("*");
      if (softwareError) {
        console.error("Error fetching software data:", softwareError);
        return;
      }
      const { data: equiposData, error: equiposError } = await supabase.from("equipos").select("id, title, attendant");
      if (equiposError) {
        console.error("Error fetching equipos data:", equiposError);
        return;
      }
      const softwareWithEquiposData = softwareData.map((software) => {
        const equipo = equiposData.find((equipo) => equipo.id === software.id_equipo);
        return {
          ...software,
          title_equipo: equipo.title,
          attendant_equipo: equipo.attendant,
        };
      });

      const originalSheet = XLSX.utils.json_to_sheet(softwareWithEquiposData);

      const additionalSheet = XLSX.utils.json_to_sheet([
        { "Column1": "CÁMARA DE COMERCIO E INDUSTRIA DE AREQUIPA" },
        { "Column1": "FICHA DE SOFTWARE DE EQUIPOS" },

      ]);

      const combinedSheet = XLSX.utils.aoa_to_sheet([
        ["CÁMARA DE COMERCIO E INDUSTRIA DE AREQUIPA"],
        ["FICHA DE SOFTWARE DE EQUIPOS"],
        [],
        ...XLSX.utils.sheet_to_json(originalSheet, { header: 1 })
      ]);

      XLSX.writeFile({ Sheets: { Combined: combinedSheet }, SheetNames: ['Combined'] }, 'Software.xlsx');
    } catch (error) {
      console.error("Error fetching and processing data:", error.message);
    }
  };

  const handleDownloadExcelPrices = async () => {
    try {
      const { data: pricesData, error: pricesError } = await supabase.from("costos").select("*");
      if (pricesError) {
        console.error("Error fetching prices data:", pricesError);
        return;
      }
      const { data: equiposData, error: equiposError } = await supabase.from("equipos").select("id, title, attendant,area");
      if (equiposError) {
        console.error("Error fetching equipos data:", equiposError);
        return;
      }
      const pricesWithEquiposData = pricesData.map((costos) => {
        const equipo = equiposData.find((equipo) => equipo.id === costos.id_equipo);
        return {
          ...costos,
          title_equipo: equipo.title,
          attendant_equipo: equipo.attendant,
          area: equipo.area,
        };
      });

      const originalSheet = XLSX.utils.json_to_sheet(pricesWithEquiposData);

      const additionalSheet = XLSX.utils.json_to_sheet([
        { "Column1": "CÁMARA DE COMERCIO E INDUSTRIA DE AREQUIPA" },
        { "Column1": "FICHA DE BACKUPS DE SERVIDORES" },
      ]);

      const combinedSheet = XLSX.utils.aoa_to_sheet([
        ["CÁMARA DE COMERCIO E INDUSTRIA DE AREQUIPA"],
        ["FICHA DE BACKUPS DE SERVIDORES"],
        [],
        ...XLSX.utils.sheet_to_json(originalSheet, { header: 1 })
      ]);

      XLSX.writeFile({ Sheets: { Combined: combinedSheet }, SheetNames: ['Combined'] }, 'Costos.xlsx');
    } catch (error) {
      console.error("Error fetching and processing data:", error.message);
    }
  };

  const handleDownloadExcelProviders = async () => {
    try {
      const { data: providersData, error: providersError } = await supabase.from("provider").select("*");
      if (providersError) {
        console.error("Error fetching prices data:", providersError);
        return;
      }
      const { data: equiposData, error: equiposError } = await supabase.from("equipos").select("id, title, attendant,area");
      if (equiposError) {
        console.error("Error fetching equipos data:", equiposError);
        return;
      }
      const { data: costosData, error: costosError } = await supabase.from("costos").select("id_equipo, costos");
      if (costosError) {
        console.error("Error fetching equipos data:", costosError);
        return;
      }
      const pricesWithEquiposData = providersData.map((provider) => {
        const equipo = equiposData.find((equipo) => equipo.id === provider.id_equipo);
        const costo = costosData.find((costo) => costo.id_equipo === provider.id_equipo);
        return {

          title_equipo: equipo.title,
          attendant_equipo: equipo.attendant,
          costos_costo: costo.costos,
          ...provider,
        };
      });

      const originalSheet = XLSX.utils.json_to_sheet(pricesWithEquiposData);

      const additionalSheet = XLSX.utils.json_to_sheet([
        { "Column1": "CÁMARA DE COMERCIO E INDUSTRIA DE AREQUIPA" },
        { "Column1": "FICHA DE PROOVEDORES DE SERVIDORES" },
      ]);

      const combinedSheet = XLSX.utils.aoa_to_sheet([
        ["CÁMARA DE COMERCIO E INDUSTRIA DE AREQUIPA"],
        ["FICHA DE PROOVEDORES DE SERVIDORES"],
        [],
        ...XLSX.utils.sheet_to_json(originalSheet, { header: 1 })
      ]);

      XLSX.writeFile({ Sheets: { Combined: combinedSheet }, SheetNames: ['Combined'] }, 'Proveedores.xlsx');
    } catch (error) {
      console.error("Error fetching and processing data:", error.message);
    }
  };

  const fetchData = async () => {
    try {
      const { data, error } = await supabase.from("equipos").select("*");

      if (error) {
        console.error("Error fetching data:", error);
      } else {
        setData(data);
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const cambiarOpcion = () => {
    setfirst(!first)
    setsecond(false)
    setthird(false)
    setfourth(false)
    setfifth(false)
    setsixth(false)
    setseventh(false)
  }
  const cambiarOpcion2 = () => {
    setsecond(!second)
    setfirst(false)
    setthird(false)
    setfourth(false)
    setfifth(false)
    setsixth(false)
    setseventh(false)
  }
  const cambiarOpcion3 = () => {
    setthird(!third)
    setsecond(false)
    setfirst(false)
    setfourth(false)
    setfifth(false)
    setsixth(false)
    setseventh(false)
  }
  const cambiarOpcion4 = () => {
    setfourth(!fourth)
    setthird(false)
    setsecond(false)
    setfirst(false)
    setfifth(false)
    setsixth(false)
    setseventh(false)
  }
  const cambiarOpcion5 = () => {
    setfifth(!fifth)
    setsecond(false)
    setthird(false)
    setfourth(false)
    setfirst(false)
    setsixth(false)
    setseventh(false)
  }
  const cambiarOpcion6 = () => {
    setsixth(!sixth)
    setfifth(false)
    setsecond(false)
    setthird(false)
    setfourth(false)
    setfirst(false)
    setseventh(false)
  }

  const cambiarOpcion7 = () => {
    setseventh(!seventh)
    setfifth(false)
    setsixth(false)
    setsecond(false)
    setthird(false)
    setfourth(false)
    setfirst(false)
  }

  return (
    <Layout>
      {loading && <Loading />}
      <div className="flex items-center justify-between">
        <h1 className="text-[#005121] font-semibold text-3xl">
          LISTADO DE EQUIPOS
        </h1>
        <div className="flex items-center gap-2">
          <button
            onClick={handleDownloadPDF}
            className="bg-[#005121] hover:bg-[#058237] transition-colors duration-300 text-sm cursor-pointer h-8 flex items-center gap-1 text-white px-2 py-1 rounded-lg"
          >
            <DownloadIcon className="w-5" />
            Descargar Etiquetas
          </button>
        </div>
      </div>
      <p className="mt-5 mb-10">
        Haz click en la imagen para ver el producto y haz click en el nombre
        para poder editarlo{" "}
      </p>
      <div className="flex gap-6">
        <button
          onClick={() => cambiarOpcion()}
          className="bg-[#058237] hover:bg-[#005121] transition-colors duration-300 text-sm cursor-pointer h-12 flex items-center gap-1 text-white px-3 py-1 rounded-xl"
        >
          HARDWARE
        </button>
        <button
          onClick={() => cambiarOpcion2()}
          className="bg-[#058237] hover:bg-[#005121] transition-colors duration-300 text-sm cursor-pointer h-12 flex items-center gap-1 text-white px-3 py-1 rounded-xl"
        >
          SOFTWARE
        </button>
        <button
          onClick={() => cambiarOpcion3()}
          className="bg-[#058237] hover:bg-[#005121] transition-colors duration-300 text-sm cursor-pointer h-12 flex items-center gap-1 text-white px-3 py-1 rounded-xl"
        >
          MANTENIMIENTO
        </button>
        <button
          onClick={() => cambiarOpcion4()}
          className="bg-[#058237] hover:bg-[#005121] transition-colors duration-300 text-sm cursor-pointer h-12 flex items-center gap-1 text-white px-3 py-1 rounded-xl"
        >
          BACKUPS
        </button>
        <button
          onClick={() => cambiarOpcion5()}
          className="bg-[#058237] hover:bg-[#005121] transition-colors duration-300 text-sm cursor-pointer h-12 flex items-center gap-1 text-white px-3 py-1 rounded-xl"
        >
          BACKUPS SERVIDOR
        </button>
        <button
          onClick={() => cambiarOpcion6()}
          className="bg-[#058237] hover:bg-[#005121] transition-colors duration-300 text-sm cursor-pointer h-12 flex items-center gap-1 text-white px-3 py-1 rounded-xl"
        >
          COSTOS
        </button>
        <button
          onClick={() => cambiarOpcion7()}
          className="bg-[#058237] hover:bg-[#005121] transition-colors duration-300 text-sm cursor-pointer h-12 flex items-center gap-1 text-white px-3 py-1 rounded-xl"
        >
          PROVEEDORES
        </button>
      </div>

      <section id="options">
        {first && (
          <>

            <div className="flex items-center gap-2 m-7 ml-0">
              <Link to="/formulario-equipo" className="bg-[#005121] hover:bg-[#058237] transition-colors duration-300 text-sm cursor-pointer h-8 flex items-center gap-1 text-white px-2 py-1 rounded-lg">
                <CreateIcon className="w-5" />
                Agregar Hardware
              </Link>
              <button
                onClick={handleDownloadExcel}
                className="bg-[#005121] hover:bg-[#058237] transition-colors duration-300 text-sm cursor-pointer h-8 flex items-center gap-1 text-white px-2 py-1 rounded-lg"
              >
                <DownloadIcon className="w-5" />
                Descargar Excel
              </button>
            </div>
            <div className="flex items-center gap-2 mb-4 mt-5">
              <input
                type="text"
                placeholder="Filtrar por nombre"
                value={filters.title}
                onChange={(e) => handleFilterChange("title", e.target.value)}
              />
              <input
                type="text"
                placeholder="Filtrar por encargado"
                value={filters.attendant}
                onChange={(e) => handleFilterChange("attendant", e.target.value)}
              />
              <input
                type="text"
                placeholder="Filtrar por área"
                value={filters.area}
                onChange={(e) => handleFilterChange("area", e.target.value)}
              />
              <input
                type="text"
                placeholder="Filtrar por marca"
                value={filters.brand}
                onChange={(e) => handleFilterChange("brand", e.target.value)}
              />
              <input
                type="text"
                placeholder="Filtrar por estado"
                value={filters.state}
                onChange={(e) => handleFilterChange("state", e.target.value)}
              />
            </div>
            <DataGrid rows={filteredData} columns={columnsHardware} style={gridStyle} rowHeight={50} minHeight={400} className="rdg-light" />
          </>
        )}
        {second && (
          <>
            <div className="flex items-center gap-2 m-7 ml-0">
              <Link to="/software/crear" className="bg-[#005121] hover:bg-[#058237] transition-colors duration-300 text-sm cursor-pointer h-8 flex items-center gap-1 text-white px-2 py-1 rounded-lg">
                <CreateIcon className="w-5" />
                Agregar Software
              </Link>
              <button
                onClick={handleDownloadExcelSoftware}
                className="bg-[#005121] hover:bg-[#058237] transition-colors duration-300 text-sm cursor-pointer h-8 flex items-center gap-1 text-white px-2 py-1 rounded-lg"
              >
                <DownloadIcon className="w-5" />
                Descargar Excel
              </button>
            </div>
            <FetchSoftware />
          </>
        )}
        {third && (
          <>
            <div className="flex items-center gap-2 m-7 ml-0">
              <Link to="/mantenimiento/crear" className="bg-[#005121] hover:bg-[#058237] transition-colors duration-300 text-sm cursor-pointer h-8 flex items-center gap-1 text-white px-2 py-1 rounded-lg">
                <CreateIcon className="w-5" />
                Agregar Mantenimiento
              </Link>
              <button
                onClick={handleDownloadExcelMaintenance}
                className="bg-[#005121] hover:bg-[#058237] transition-colors duration-300 text-sm cursor-pointer h-8 flex items-center gap-1 text-white px-2 py-1 rounded-lg"
              >
                <DownloadIcon className="w-5" />
                Descargar Excel
              </button>
            </div>
            <FetchMaintenance />
          </>)}
        {fourth && (
          <>
            <div className="flex items-center gap-2 m-7 ml-0">
              <Link to={`/backups/crear`} className="bg-[#005121] hover:bg-[#058237] transition-colors duration-300 text-sm cursor-pointer h-8 flex items-center gap-1 text-white px-2 py-1 rounded-lg">
                <CreateIcon className="w-5" />
                Agregar backup
              </Link>
              <button
                onClick={handleDownloadExcelBackups}
                className="bg-[#005121] hover:bg-[#058237] transition-colors duration-300 text-sm cursor-pointer h-8 flex items-center gap-1 text-white px-2 py-1 rounded-lg"
              >
                <DownloadIcon className="w-5" />
                Descargar Excel
              </button>
            </div>
            <FetchBackups />
          </>
        )}
        {fifth && (
          <>
            <div className="flex items-center gap-2 m-7 ml-0">
              <Link to={`/backups-server/crear`} className="bg-[#005121] hover:bg-[#058237] transition-colors duration-300 text-sm cursor-pointer h-8 flex items-center gap-1 text-white px-2 py-1 rounded-lg">
                <CreateIcon className="w-5" />
                Agregar backup del servidor
              </Link>
              <button
                onClick={handleDownloadExcelBackupsServer}
                className="bg-[#005121] hover:bg-[#058237] transition-colors duration-300 text-sm cursor-pointer h-8 flex items-center gap-1 text-white px-2 py-1 rounded-lg"
              >
                <DownloadIcon className="w-5" />
                Descargar Excel
              </button>
            </div>
            <div>
              <div className="flex gap-2">
                <p className="font-bold">Sistema operativo:</p>
                <p className="font-normal">LINUX</p>
              </div>
              <div className="flex gap-2 pt-5">
                <p className="font-bold">Uso Del Disco:</p>
                <p className="font-normal">126,95 GB / 150 GB (84,63%)</p>
              </div>
            </div>
            <FetchBackupsServer />
          </>
        )}
        {sixth && (
          <>
            <div className="flex items-center gap-2 m-7 ml-0">
              <Link to={`/costos/crear`} className="bg-[#005121] hover:bg-[#058237] transition-colors duration-300 text-sm cursor-pointer h-8 flex items-center gap-1 text-white px-2 py-1 rounded-lg">
                <CreateIcon className="w-5" />
                Agregar costo de equipo
              </Link>
              <button
                onClick={handleDownloadExcelPrices}
                className="bg-[#005121] hover:bg-[#058237] transition-colors duration-300 text-sm cursor-pointer h-8 flex items-center gap-1 text-white px-2 py-1 rounded-lg"
              >
                <DownloadIcon className="w-5" />
                Descargar Excel
              </button>
            </div>
            <FetchPrices />
          </>
        )}
        {seventh && (
          <>
            <div className="flex items-center gap-2 m-7 ml-0">
              <Link to={`/proveedor/crear`} className="bg-[#005121] hover:bg-[#058237] transition-colors duration-300 text-sm cursor-pointer h-8 flex items-center gap-1 text-white px-2 py-1 rounded-lg">
                <CreateIcon className="w-5" />
                Agregar proveedor
              </Link>
              <button
                onClick={handleDownloadExcelProviders}
                className="bg-[#005121] hover:bg-[#058237] transition-colors duration-300 text-sm cursor-pointer h-8 flex items-center gap-1 text-white px-2 py-1 rounded-lg"
              >
                <DownloadIcon className="w-5" />
                Descargar Excel
              </button>
            </div>
            <FetchProvider />
          </>
        )}
      </section>
    </Layout>
  );
};

export default ProductsPage;
