import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DataGrid from "react-data-grid";
import { supabase } from "../client/client";
import "react-data-grid/lib/styles.css";

const FetchPrices = () => {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [filters, setFilters] = useState({
        attendant: "",
        title: "",
        area: "",
    });
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");

    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    
    const columnsPrices = [
        { field: "attendant", name: "Encargado", width: 300, key: "attendant" },
        {
            field: "title",
            name: "Nombre",
            width: 300,
            key: "title",
            renderCell: ({ row }) => (
                <Link to={`/backups/${row.id}/editar`} underline="always">
                    {row.title}
                </Link>
            ),
        },
        {
            field: "costos",
            name: "Costos",
            width: 220,
            key: "costos",
            renderCell: ({ row }) => `S/. ${row.costos}`
        },
        { field: "area", name: "Área", width: 300, key: "area" },
        { field: "shipping_date", name: "Fecha de compra", width: 200, key: "shipping_date" },
    ];

    const gridStyle = {
        minHeight: '500px',
    };

    const fetchData = async () => {
        setLoading(true);
        setMessage("");

        try {
            const { data: pricesData, error: pricesError } = await supabase
                .from('costos')
                .select('*');
                console.log("data costos",pricesData);

            if (pricesError) {
                throw pricesError;
            }

            const pricesIds = pricesData.map(costos => costos.id_equipo);
            console.log("IDs de equipos a buscar:", pricesIds);

            const { data: equiposData, error: equiposError } = await supabase
                .from('equipos')
                .select('id, title, attendant, area');
                console.log("dataaaa",equiposData);

            if (equiposError) {
                console.error("Error en la consulta de equipos:", equiposError);
                throw equiposError;
            }

            const combinedData = pricesData.map((costos) => {
                const equipo = equiposData.find((equipo) => equipo.id === costos.id_equipo);
                return {
                    ...costos,
                    title: equipo?.title || 'Sin título',
                    attendant: equipo?.attendant || 'No asignado',
                    area: equipo?.area || 'Sin área',
                };
            });

            setData(combinedData);
            setFilteredData(combinedData);

        } catch (error) {
            console.error('Error fetching data:', error);
            setMessage(`Error al cargar los datos: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    const handleFilterChange = (filterName, value) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [filterName]: value,
        }));
    };

    useEffect(() => {
        const filtered = data.filter((row) =>
            Object.entries(filters).every(([key, value]) =>
                value ? row[key].toLowerCase().includes(value.toLowerCase()) : true
            )
        );
        setFilteredData(filtered);
    }, [filters, data]);

    const totalRows = filteredData.length;
    const totalPages = Math.ceil(totalRows / rowsPerPage);
    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const currentData = filteredData.slice(startIndex, endIndex);

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <div className="flex items-center gap-2 mb-4 mt-5">
                <input
                    type="text"
                    placeholder="Filtrar por encargado"
                    value={filters.attendant}
                    onChange={(e) => handleFilterChange("attendant", e.target.value)}
                />
                <input
                    type="text"
                    placeholder="Filtrar por nombre"
                    value={filters.title}
                    onChange={(e) => handleFilterChange("title", e.target.value)}
                />
                <input
                    type="text"
                    placeholder="Filtrar por área"
                    value={filters.area}
                    onChange={(e) => handleFilterChange("area", e.target.value)}
                />
            </div>
            {loading && <div>Cargando...</div>}
            {message && <div>{message}</div>} 
            <DataGrid
                rows={currentData}
                columns={columnsPrices}
                style={gridStyle}
                rowHeight={50}
                minHeight={400}
                className="rdg-light"
            />
            <div className="pagination" style={{display:'flex',  gap: '20px', marginTop: '20px', fontWeight: '800'}}>
                <button
                    onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                    disabled={currentPage === 1}
                >
                    Anterior
                </button>
                <span>
                    Página {currentPage} de {totalPages}
                </span>
                <button
                    onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                    disabled={currentPage === totalPages}
                >
                    Siguiente
                </button>
            </div>
        </>
    );
};

export default FetchPrices;
