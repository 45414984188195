import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DataGrid from "react-data-grid";
import "jspdf-autotable";
import { supabase } from "../client/client";
import "react-data-grid/lib/styles.css";

const FetchProvider = () => {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [filters, setFilters] = useState({
        razon_social: "",
        title: "",
        attendant: "",
        area: "",
    });


    const columnProvider = [
        { field: "attendant", name: "Encargado", width: 240, key: "attendant" },
        {
            field: "title",
            name: "Nombre",
            width: 200,
            key: "title",
            renderCell: ({ row }) => {
                return (
                    <Link to={`/backups/${row.id}/editar`} underline="always">
                        {row.title}
                    </Link>
                );
            },
        },
        { field: "area", name: "Área", width: 260, key: "area" },
        { field: "razon_social", name: "Razón Social", width: 280, key: "razon_social" },
        { field: "ruc", name: "RUC", width: 150, key: "ruc" },
        { field: "comprobante", name: "N° de comprobante", width: 200, key: "comprobante" },
    ];

    const gridStyle = {
        minHeight: '500px',
    };

    const fetchData = async () => {
        try {
            const { data: providerData, error: providerError } = await supabase
                .from('provider')
                .select('*');
            if (providerError) {
                throw providerError;
            }
            const providerIds = providerData.map(provider => provider.id_equipo);
            const { data: equiposData, error: equiposError } = await supabase
                .from('equipos')
                .select('id, title, attendant, area')
                .in('id', providerIds);
            if (equiposError) {
                throw equiposError;
            }
            const combinedData = providerData.map((provider) => {
                const equipo = equiposData.find((equipo) => equipo.id === provider.id_equipo);
                return {
                    ...provider,
                    title: equipo.title,
                    attendant: equipo.attendant,
                    area: equipo.area,
                };
            });

            // Filtrar los datos en base a los filtros
            const filteredData = combinedData.filter((row) =>
                Object.entries(filters).every(([key, value]) =>
                    row[key].toLowerCase().includes(value.toLowerCase())
                )
            );

            setData(combinedData); // Actualizar data
            setFilteredData(filteredData); // Actualizar filteredData
        } catch (error) {
            console.error('Error fetching data:', error.message);
        }
    };

    const handleFilterChange = (filterName, value) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [filterName]: value,
        }));
    };

    useEffect(() => {
        fetchData();
    }, [filters]);


    return (
        <>
            <div className="flex items-center gap-2 mb-4 mt-5">
                <input
                    type="text"
                    placeholder="Filtrar por encargado"
                    value={filters.attendant}
                    onChange={(e) => handleFilterChange("attendant", e.target.value)}
                />
                <input
                    type="text"
                    placeholder="Filtrar por nombre"
                    value={filters.title}
                    onChange={(e) => handleFilterChange("title", e.target.value)}
                />
                <input
                    type="text"
                    placeholder="Filtrar por área"
                    value={filters.area}
                    onChange={(e) => handleFilterChange("area", e.target.value)}
                />
                <input
                    type="text"
                    placeholder="Filtrar por razón social"
                    value={filters.razon_social}
                    onChange={(e) => handleFilterChange("razon_social", e.target.value)}
                />
            </div>

            <DataGrid rows={filteredData} columns={columnProvider} style={gridStyle} rowHeight={50} minHeight={400} className="rdg-light" />
        </>
    )
}

export default FetchProvider